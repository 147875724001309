import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { datadogRum } from '@datadog/browser-rum';

// https://app.datadoghq.com/rum/application/a3b23589-e9d3-4321-9a52-8c7a30f3fb22
export default defineNuxtPlugin(({ isDev, $config }) => {
    if (isDev || !process.client) return;

    datadogRum.init({
        applicationId: '5a3eb96e-35f8-4d75-a302-c5322c8746d0',
        clientToken: 'pubec49a4704d755a69331e72e28d251af7',
        site: 'datadoghq.com',
        service: 'es-cms-local-data',
        env: $config.DEPLOY_ENV,
        version: $config.VERSION,
        sessionSampleRate: 100, // track 100% of sessions
        sessionReplaySampleRate: 20, // capture replays of 20% of traffic (default)
        trackUserInteractions: true, // enables the automatic collection of user clicks in your application
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input', // password & email will still be masked
        trackSessionAcrossSubdomains: true, // Presumably this unlocks local-data. or accounts.-> www.
    });

    datadogRum.startSessionReplayRecording();

    // TODO: check for UUID cookie?
    // datadogRum.setUser(<USER_CONFIG_OBJECT>)?
});
